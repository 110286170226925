<template>
  <v-stepper-step
    :step="step"
    :complete-icon="stepIcon"
    complete
    color="white"
    class="message-item pt-3 pb-2"
  >
    <p class="message-item__title text-body-2 font-weight-bold">
      {{ title }}
    </p>
    <p
      v-if="createdAt"
      class="message-item__time text-body-2 font-weight-regular"
    >
      {{ dateToLocalString(createdAt) }}
    </p>
  </v-stepper-step>
</template>

<script>
import dateToLocalString from "@/helpers/dateToLocalString";
import { AuditMessageTypes } from "@/misc/constants";

export default {
  name: "AuditMessagesTitle",

  props: {
    step: {
      type: Number,
      required: true,
    },
    messageType: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    createdAt: {
      type: [Date, String],
      default: null,
    },
  },

  computed: {
    stepIcon() {
      switch (this.messageType) {
        case AuditMessageTypes.reply:
          return "mdi-message-alert";
        case AuditMessageTypes.conclusion:
          return "mdi-alert-outline";
        case AuditMessageTypes.resolutionNote:
          return "mdi-alert";
        case AuditMessageTypes.communication:
          return "mdi-note-text";
        default:
          return "mdi-note-text";
      }
    },
  },

  methods: {
    dateToLocalString,
  },
};
</script>

<style lang="scss">
.message-item {
  &__title {
    text-shadow: none;
  }

  &__time {
    text-shadow: none;
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .v-icon {
    color: #424242 !important;
  }
}
</style>
