<template>
  <section class="case-summary">
    <v-divider class="mb-3" />

    <div class="d-flex justify-start flex-wrap">
      <CaseDetailsItem
        v-for="(item, index) in items"
        :key="item.title"
        :title="item.title"
        :description="item.text"
        :width="$vuetify.breakpoint.mobile ? '50%' : '33%'"
        :class="{
          'mb-4': $vuetify.breakpoint.mobile && index < items.length - 1,
        }"
      />
    </div>

    <v-divider class="mb-3 mt-3" />

    <p class="case-summary__text">{{ summary }}</p>
  </section>
</template>

<script>
import CaseDetailsItem from "./CaseDetailsItem.vue";

export default {
  name: "CaseDetailsSummary",

  components: {
    CaseDetailsItem,
  },

  props: {
    clinicianName: {
      type: String,
      required: true,
    },
    startTime: {
      type: String,
      required: true,
    },
    endTime: {
      type: String,
      required: true,
    },
    summary: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      items: [
        {
          title: "Clinician Name",
          text: this.clinicianName,
        },
        {
          title: "Start Time",
          text: this.startTime,
        },
        {
          title: "End Time",
          text: this.endTime,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.case-summary {
  .section {
    width: 33%;
  }

  &__text {
    white-space: pre-wrap;
  }
}
</style>
