<template>
  <v-card
    :disabled="disabled"
    flat
    outlined
    class="case-clinician-info d-flex justify-start align-center pa-2 pl-0 rounded-0"
    style="border: none; box-shadow: none"
  >
    <CliniciansNameBadge
      v-if="isBadgeVisible"
      :clinician-status="clinician.category"
      class="mr-3 ml-3"
    />

    <div class="d-flex flex-column justify-start align-start">
      <p class="text-subtitle-1 font-weight-bold line-height">
        {{ label }}
      </p>
      <p class="text-h6 font-weight-light">
        {{ clinician.name || "" }}
      </p>
      <p class="text--secondary">
        {{ clinician?.position?.name || "" }}
      </p>
    </div>
  </v-card>
</template>

<script>
import CliniciansNameBadge from "../../common/CliniciansNameBadge.vue";

export default {
  name: "CaseDetailsClinician",

  components: {
    CliniciansNameBadge,
  },

  props: {
    clinician: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: () => "Clinician",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    isBadgeVisible() {
      return !!this.clinician?.category;
    },
  },
};
</script>
