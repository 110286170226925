<template>
  <div class="case-note">
    <div
      class="case-note__header d-flex justify-space-between align-center mb-3"
    >
      <div class="case-note__header__user d-flex">
        <v-avatar class="mr-3">
          <v-img :src="photo" :alt="note.author" />
        </v-avatar>
        <div>
          <h4 class="case-note__author text-body-2">
            {{ note.userName }}
          </h4>
          <p class="case-note__date text-body-2 font-weight-regular">
            {{ getNoteDate(note) }}
          </p>
        </div>
      </div>

      <slot v-if="isTopBarActive" name="top-bar" :note="note" />
    </div>

    <slot name="text" :note="note">
      <p>{{ note.text }}</p>
    </slot>

    <slot v-if="isBottomBarActive" name="bottom-bar" :note="note" />

    <v-divider v-if="showDivider" class="mt-3" />
  </div>
</template>

<script>
import photo from "@/assets/unknown-user.png";
import dateToLocalString from "@/helpers/dateToLocalString";

export default {
  name: "Note",

  props: {
    note: {
      type: Object,
      required: true,
    },
    showDivider: {
      type: Boolean,
      default: () => false,
    },
    isBottomBarActive: {
      type: Boolean,
      default: () => false,
    },
    isTopBarActive: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      photo,
    };
  },

  methods: {
    getNoteDate(note) {
      return note?.updatedAt
        ? dateToLocalString(note?.updatedAt)
        : dateToLocalString(note.createdAt);
    },
  },
};
</script>

<style lang="scss" scoped>
.case-note {
  &__author {
    font-size: 14px;
    font-weight: 600;
  }

  &__date {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>
