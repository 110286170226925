<template>
  <span class="clinicians-name-badge rounded-circle" :style="badgeStyle" />
</template>

<script>
import { CliniciansStatusColors, ClinicianStatuses } from "@/misc/constants";

export default {
  name: "CliniciansNameBadge",

  props: {
    clinicianStatus: {
      type: String,
      required: true,
    },
  },

  computed: {
    badgeStyle() {
      return {
        "background-color": Object.values(ClinicianStatuses).includes(
          this.clinicianStatus
        )
          ? CliniciansStatusColors[this.clinicianStatus]
          : CliniciansStatusColors.none,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.clinicians-name-badge {
  min-width: 12px;
  min-height: 12px;
}
</style>
