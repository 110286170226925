<template>
  <div class="review-messages">
    <v-card class="pt-6 pb-6">
      <h4 class="pl-6 pr-6 text-body-1 font-weight-bold">Communication Log</h4>

      <v-stepper :flat="true" vertical class="pb-0">
        <template v-for="(message, index) in auditMessages">
          <AuditMessagesTitle
            :key="message._id"
            :step="index"
            :title="message.title"
            :created-at="message?.createdAt"
            :message-type="message?.type"
          />

          <AuditMessagesText
            :key="`${message._id}-text`"
            :step="index"
            :text="message?.text"
          />
        </template>
      </v-stepper>

      <slot name="actions" />
    </v-card>
  </div>
</template>

<script>
import AuditMessagesTitle from "@/components/case/AuditMessages/AuditMessagesTitle.vue";
import AuditMessagesText from "@/components/case/AuditMessages/AuditMessagesText.vue";

export default {
  name: "AuditCommunication",

  components: {
    AuditMessagesTitle,
    AuditMessagesText,
  },

  props: {
    auditMessages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.review-messages {
  .v-stepper__wrapper {
    height: auto !important;
  }
}
</style>
