<template>
  <v-stepper-content v-if="text" :step="step">
    <v-card color="grey lighten-3" class="pa-2">
      {{ text }}
    </v-card>
  </v-stepper-content>
  <v-stepper-content v-else :step="step" />
</template>

<script>
export default {
  name: "AuditMessagesText",

  props: {
    step: {
      type: Number,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
  },
};
</script>
