<template>
  <v-card
    class="review-panel d-flex flex-row justify-space-between align-center mb-5 pa-4 pt-2 pb-2"
  >
    <div>
      <h1 class="text-subtitle-1 font-weight-bold">
        {{ get(batchUnderReview, "name", "") }}
      </h1>
      <p class="text-subtitle-1 text-medium-emphasis">
        {{ get(auditTemplate, "name", "") }}
      </p>
    </div>

    <slot name="actions" />
  </v-card>
</template>

<script>
import { mapState } from "pinia";
import { get } from "@/helpers/get";
import { useBatchReviewStore } from "@/stores/batchReview";

export default {
  name: "BatchReviewPanel",

  computed: {
    ...mapState(useBatchReviewStore, ["batchUnderReview", "auditTemplate"]),
  },

  methods: {
    get,
  },
};
</script>
