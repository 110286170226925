<template>
  <div :style="{ width }" class="d-flex flex-column justify-start">
    <h4 class="ext-body-1 font-weight-bold pb-1">
      {{ title }}
    </h4>
    <p class="text-left text-body-2" style="white-space: pre-line">
      <slot name="description">
        {{ description }}
      </slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "CaseDetailsItem",

  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: [String, Number],
      default: "",
    },
    width: {
      type: String,
      default: "auto",
    },
  },
};
</script>
