<template>
  <div class="review-progress-bar d-flex justify-start align-center">
    <v-btn
      :disabled="auditIndex === 0 || disabled"
      color="primary"
      class="review-progress-bar__nav-btn pa-0"
      @click="onNavigationClick(-1)"
    >
      <v-icon> mdi-arrow-left </v-icon>
    </v-btn>
    <div class="pl-2 pr-2">
      <span>Case {{ auditIndex + 1 }} of {{ auditsLength }}</span>
    </div>
    <div>
      <v-tooltip
        v-if="auditIndex !== auditsLength - 1 && disabled"
        bottom
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              :disabled="auditIndex === auditsLength - 1 || disabled"
              color="primary"
              class="review-progress-bar__nav-btn pa-0"
              @click="onNavigationClick(1)"
            >
              <v-icon> mdi-arrow-right </v-icon>
            </v-btn>
          </div>
        </template>
        <span>{{ tooltipMessage }}</span>
      </v-tooltip>
      <v-btn
        v-else
        :disabled="auditIndex === auditsLength - 1 || disabled"
        color="primary"
        class="review-progress-bar__nav-btn pa-0"
        @click="onNavigationClick(1)"
      >
        <v-icon> mdi-arrow-right </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useBatchReviewStore } from "../../stores/batchReview";

export default {
  name: "ReviewProgressBar",

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isNextLoading: false,
      isPrevLoading: false,
      tooltipMessage: "Answer audit questions to unlock the next case.",
    };
  },

  computed: {
    ...mapState(useBatchReviewStore, [
      "batchUnderReview",
      "auditIndex",
      "auditsLength",
      "currentCaseUnderReview",
    ]),
  },

  methods: {
    ...mapActions(useBatchReviewStore, ["changeAuditIndex"]),
    async handleNavigateChange(nextIndex) {
      try {
        this.toggleButtonsLoading(nextIndex);
        await this.changeAuditIndex(nextIndex);
        const { _id, caseType } = this.currentCaseUnderReview;
        const { prev, current } = this.$route.query;
        this.$router.push({
          path: `/cases/${_id}`,
          query: { caseType, underReview: true, prev, current, auditIndex: this.auditIndex },
        });
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Fetch Group Review Data",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.toggleButtonsLoading(nextIndex);
      }
    },
    onNavigationClick(nextIndex) {
      this.$emit("change", (() => this.handleNavigateChange(nextIndex)).bind());
    },
    toggleButtonsLoading(index) {
      return index > 0
        ? (this.isNextLoading = !this.isNextLoading)
        : (this.isPrevLoading = !this.isPrevLoading);
    },
  },
};
</script>

<style lang="scss" scoped>
.review-progress-bar {
  &__nav-btn {
    min-width: 32px !important;
    min-height: 32px;
    height: auto !important;
    width: auto;
  }
}
</style>
