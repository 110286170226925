import { axiosInstance } from "../setupInterceptors";

const endpointUrl = "/audit-templates";

export const getAuditTemplates = (params = {}) =>
  axiosInstance.get(endpointUrl, { params }).then((response) => response.data);

export const getAuditTemplate = (id) =>
  axiosInstance.get(`${endpointUrl}/${id}`).then((response) => response.data);

export const createAuditTemplate = (data) =>
  axiosInstance.post(endpointUrl, data).then((response) => response.data);

export const updateAuditTemplate = (id, data) =>
  axiosInstance
    .patch(`${endpointUrl}/${id}`, data)
    .then((response) => response.data);

export const deleteAuditTemplate = (id) =>
  axiosInstance
    .delete(`${endpointUrl}/${id}`)
    .then((response) => response.data);

export const uploadDocumentation = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return axiosInstance
    .put(`${endpointUrl}/upload-documentation`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

export const updateAuditServices = (id, services) =>
  axiosInstance.patch(`${endpointUrl}/${id}/update-services`, services).then((response) => response.data);
