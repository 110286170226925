<template>
  <v-card class="auditor-notes pa-6 pt-7" color="#F0F0F0">
    <div
      class="auditor-notes__header d-flex justify-space-between align-center pb-3"
    >
      <h4 class="auditor-notes__title font-weight-bold text-h6 text-left">
        Notes
      </h4>
      <div class="d-flex justify-end align-center">
        <v-btn
          v-if="!$vuetify.breakpoint.mobile"
          color="primary"
          rounded
          normal
          variant="elevated"
          @click="onAddNoteClick"
        >
          <v-icon class="mr-2"> mdi-plus </v-icon>
          <span>ADD</span>
        </v-btn>
        <v-btn v-else color="primary" icon @click="onAddNoteClick">
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </div>
    </div>
    <div class="auditor-notes__list d-flex flex-column">
      <Note
        v-for="(note, ind) in caseNotes"
        :key="note.id"
        :note="note"
        :is-bottom-bar-active="true"
        :show-divider="ind !== caseNotes.length - 1"
      >
        <template v-slot:text="data">
          <ExpandableTextBlock
            v-if="data.note?.text?.length > maximumVisibleSymbols"
            :content="note.text"
            :visible-lines="3"
            :line-height="16"
          />
          <p v-else>{{ data.note?.text }}</p>
        </template>

        <template v-if="checkIsNoteEditable(note)" v-slot:bottom-bar>
          <v-btn
            color="black"
            rounded
            outlined
            small
            variant="elevated"
            class="mb-3 mt-3"
            @click="onEditNoteClick(note)"
          >
            <v-icon class="mr-2"> mdi-pencil </v-icon>
            <span>EDIT</span>
          </v-btn>
        </template>
      </Note>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import Note from "./Note.vue";
import ExpandableTextBlock from "../common/ExpandableTextBlock.vue";

export default {
  name: "AuditorNotes",

  components: {
    Note,
    ExpandableTextBlock,
  },

  props: {
    caseNotes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      maximumVisibleSymbols: 200,
    };
  },

  computed: {
    ...mapState(useUserStore, ["user"]),
  },

  methods: {
    checkIsNoteEditable(note) {
      return this.user._id === note?.userId;
    },
    onAddNoteClick() {
      this.$emit("add");
    },
    onEditNoteClick(note) {
      this.$emit("edit", note);
    },
  },
};
</script>

<style lang="scss" scoped>
.auditor-notes {
  &__list {
    gap: 12px;
  }
}
</style>
