<template>
  <v-card class="pa-5">
    <v-card-title class="pa-0 pb-4">Audit History</v-card-title>

    <section class="d-flex flex-column">
      <div
        v-for="log in logs"
        :key="log._id"
        class="d-flex justify-start align-start"
      >
        <v-icon class="mr-2">{{ getLogIcon(log) }}</v-icon>
        <p class="text-subtitle-2 font-weight-medium">{{ log.text }}</p>
      </div>
    </section>
  </v-card>
</template>

<script>
const LogTypes = {
  info: "info",
  additional: "additional",
};

const IconsTypes = {
  [LogTypes.info]: "mdi-information",
  [LogTypes.additional]: "mdi-update",
};

export default {
  name: "AuditCaseHistory",

  props: {
    logs: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getLogIcon({ type = null }) {
      return type ? IconsTypes[type] : "mdi-alert";
    },
  },
};
</script>
