<template>
  <v-dialog :value="dialog" width="616" persistent style="z-index: 20001">
    <v-card class="pa-7">
      <v-card-title class="text-h5 lighten-2 pa-0">
        Add to Another Audit
      </v-card-title>
      <v-card-text class="pa-0">Choose Audit Template</v-card-text>
      <v-form class="pt-5">
        <AuditTemplateSelect
          :value="templateId"
          :item-value="'_id'"
          :hide-details="false"
          :error-messages="templateIdErrors"
          :audit-type-filter="[BatchTypes.docReview, BatchTypes.general]"
          @change="onTemplateChange"
          @blur="$v.templateId.$touch()"
        />
      </v-form>
      <v-card-actions class="pa-0 pt-4">
        <v-spacer />
        <v-btn color="primary" text @click="toggleDialogView">Cancel</v-btn>
        <v-btn
          :disabled="$v.$invalid"
          :loading="loading"
          color="primary"
          rounded
          normal
          variant="elevated"
          @click="handleModalSendClick"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import AuditTemplateSelect from "../controls/AuditTemplateSelect.vue";
import { BatchTypes } from "@/misc/constants";

export default {
  name: "AddAdditionalAuditModal",

  components: {
    AuditTemplateSelect,
  },

  mixins: [validationMixin],

  props: {
    dialog: {
      type: Boolean,
      required: false,
    },
    auditTemplateId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      BatchTypes,
      loading: false,
      templateId: null,
    };
  },

  validations: {
    templateId: {
      required,
      isValid: function (value) {
        return !(this.auditTemplateId && this.auditTemplateId === value);
      },
    },
  },

  computed: {
    templateIdErrors() {
      const errors = [];
      if (!this.$v.templateId.$dirty) return errors;
      !this.$v.templateId.required && errors.push("Template is required");
      !this.$v.templateId.isValid &&
        errors.push(
          "You can't add additional audit for case with the same Audit Template"
        );
      return errors;
    },
  },

  methods: {
    toggleDialogView() {
      this.text = null;
      this.$emit("toggle", !this.dialog);
    },
    handleModalSendClick() {
      this.loading = true;
      const loadingCallback = () => {
        this.loading = false;
        this.toggleDialogView();
      };
      this.$emit("submit", this.templateId, loadingCallback.bind(this));
    },
    onTemplateChange(templateId) {
      this.templateId = templateId;
      this.$v.templateId.$touch();
    },
  },
};
</script>
