import { get } from "./get";
import dateToLocalString from "./dateToLocalString";

export function getDateForDisplay(object, key, format = true) {
  const date = get(object, key, null);
  if (!date) return "-";
  return dateToLocalString(date, format);
}

export function minutesToDays(minutes) {
  if (minutes == '-') {
    return '-'
  }

  const days = Math.floor(minutes / (60 * 24));
  const hours = Math.floor((minutes - days * 60 * 24) / 60);
  const minutesFormated = minutes - days * 60 * 24 - hours * 60;

  if (days !== 0) return `${days}d ${hours}h ${minutesFormated}m`
  if (hours !== 0) return `${hours}h ${minutesFormated}m`
  return `${minutesFormated}m`

}
