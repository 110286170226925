<template>
  <v-dialog
    :value="dialog"
    max-width="1152"
    persistent
    style="z-index: 200013"
    class="batch-clinicians-result"
  >
    <v-card class="pa-5">
      <v-card-title class="text-h5 lighten-2 pa-0 mb-4">
        Clinicians' emails are not verified
      </v-card-title>
      <v-card-subtitle class="pa-0">
        Notification emails are not sent to the next clinicians. Please check
        the verification status of them:
      </v-card-subtitle>

      <v-data-table
        :headers="tableHeaders"
        :items="data"
        :items-per-page="10"
        :footer-props="tableFooterProps"
        class="ResultsTable elevation-1 mt-3"
      >
        <template #[`item.verificationStatus`]="{ item }">
          <v-chip
            small
            label
            :color="VerificationStatusColors[item.verificationStatus]"
          >
            {{ UserStatusesForView[item.verificationStatus] || "-" }}
          </v-chip>
        </template>

        <template #[`item.conclusionStatuses`]="{ item }">
          <v-chip
            v-for="(status, ind) of item.conclusionStatuses"
            :key="`${item.name}-${ind}`"
            outlined
          >
            {{ status }}
          </v-chip>
        </template>
      </v-data-table>

      <v-card-actions class="pa-0 mt-3">
        <v-spacer />
        <v-btn color="primary" rounded @click="$emit('close')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  UserStatusesForView,
  VerificationStatusColors,
  CasesStatuses,
} from "@/misc/constants";

export default {
  name: "InvalidCliniciansModal",

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const tableHeaders = [
      {
        text: "Clinician Name",
        value: "name",
        sortable: false,
      },
      {
        text: "Verification Status",
        value: "verificationStatus",
        sortable: false,
      },
      {
        text: "Reviewed Statuses",
        value: "conclusionStatuses",
        sortable: false,
      },
    ];

    return {
      tableHeaders,
      tableFooterProps: {
        "items-per-page-options": [5, 10, 15],
      },
      UserStatusesForView,
      VerificationStatusColors,
      CasesStatuses,
    };
  },
};
</script>
