<template>
  <v-autocomplete
    :value="value"
    :items="templates"
    :loading="isLoading"
    :menu-props="{ bottom: true, offsetY: true }"
    hide-no-data
    hide-selected
    :hide-details="hideDetails"
    :error-messages="errorMessages"
    outlined
    item-text="name"
    item-value="_id"
    label="Audit template"
    placeholder="Start typing to Search"
    clearable
    return-object
    @update:search-input="onInputUpdate"
    @change="handleFieldChange"
    @click:clear="$emit('change', null)"
    @blur="$emit('blur')"
  />
</template>

<script>
import { debounce } from "lodash";
import { getAuditTemplates } from "@/services/auditTemplates";
import { BatchTypes } from "@/misc/constants";

export default {
  name: "AuditTemplateSelect",

  props: {
    value: {
      type: [String, Object],
      default: () => null,
    },
    hideDetails: {
      type: Boolean,
      default: () => true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    auditTypeFilter: {
      type: Array,
      default: () => [BatchTypes.docReview]
    }
  },

  data() {
    return {
      isLoading: false,
      templates: [],
    };
  },

  async mounted() {
    await this.fetchTemplates();
  },

  methods: {
    handleFieldChange(template) {
      this.$emit("change", template ? template._id : null, template?.auditType);
    },
    buildSearchQuery(value) {
      const filters = value ? { name: { $regex: value, $options: "i" } } : {};
      return {
        select: ["name", "_id", "auditType"],
        filter: {
          ...filters,
          auditType: {$nin: this.auditTypeFilter },
        },
        pageSize: 50,
      };
    },
    async fetchTemplates(value = null) {
      try {
        this.isLoading = true;
        const query = this.buildSearchQuery(value);
        const { data } = await getAuditTemplates(query);
        this.templates = data;
        this.loading = false;
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.isLoading = false;
      }
    },
    onInputUpdate: debounce(function (value) {
      this.fetchTemplates(value);
    }, 1500),
  },
};
</script>
