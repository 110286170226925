<template>
  <div class="mx-5 w-100">
    <v-tooltip v-if="nextCaseDisabled && !isLastCase" bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn
            :disabled="true"
            rounded
            color="primary"
            class="mb-3 w-100"
            @click="onNavigationClick(1)"
          >
            Next case
          </v-btn>
        </div>
      </template>
      <span>Answer audit questions to unlock the next case.</span>
    </v-tooltip>
    <v-btn
      v-else-if="isLastCase"
      :disabled="true"
      rounded
      color="primary"
      class="mb-3 w-100"
      @click="onNavigationClick(1)"
    >
      Next case
    </v-btn>
    <v-btn
      v-else
      :disabled="false"
      rounded
      color="primary"
      class="mb-3 w-100"
      @click="onNavigationClick(1)"
    >
      Next case
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useBatchReviewStore } from "../../stores/batchReview";

export default {
  name: "ReviewNextCaseButton",

  props: {
    nextCaseDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tooltipMessage: "Answer audit questions to unlock the next case.",
    };
  },

  computed: {
    ...mapState(useBatchReviewStore, [
      "batchUnderReview",
      "auditIndex",
      "auditsLength",
      "currentCaseUnderReview",
    ]),
    isLastCase() {
      return this.auditIndex + 1 === this.auditsLength;
    },
  },

  methods: {
    ...mapActions(useBatchReviewStore, ["changeAuditIndex"]),
    async handleNavigateChange(nextIndex) {
      try {
        this.toggleButtonsLoading(nextIndex);
        await this.changeAuditIndex(nextIndex);
        const { _id, caseType } = this.currentCaseUnderReview;
        const { prev, current } = this.$route.query;
        this.$router.push({
          path: `/cases/${_id}`,
          query: { caseType, underReview: true, prev, current },
        });
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Fetch Group Review Data",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.toggleButtonsLoading(nextIndex);
      }
    },
    onNavigationClick(nextIndex) {
      this.$emit("change", (() => this.handleNavigateChange(nextIndex)).bind());
    },
    toggleButtonsLoading(index) {
      return index > 0
        ? (this.isNextLoading = !this.isNextLoading)
        : (this.isPrevLoading = !this.isPrevLoading);
    },
  },
};
</script>
