<template>
  <div class="review-messages">
    <v-card class="pt-6 pb-6">
      <h4 class="pl-6 pr-6 text-body-1 font-weight-bold">Review Log</h4>

      <v-stepper :flat="true" vertical class="pb-0">
        <template v-for="(message, index) in messageItems">
          <AuditMessagesTitle
            :key="message._id"
            :step="index"
            :title="message.title"
            :created-at="message?.createdAt"
            :message-type="message?.type"
          />

          <AuditMessagesText
            :key="`${message._id}-text`"
            :step="index"
            :text="message?.text"
          />
        </template>
      </v-stepper>

      <slot name="actions" />
    </v-card>
  </div>
</template>

<script>
import { AuditMessageTypes } from "@/misc/constants";
import AuditMessagesTitle from "./AuditMessagesTitle.vue";
import AuditMessagesText from "./AuditMessagesText.vue";

export default {
  name: "AuditMessages",

  components: {
    AuditMessagesTitle,
    AuditMessagesText,
  },

  props: {
    auditMessages: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    messageItems() {
      return this.auditMessages.reduce((acc, message) => {
        if (message.type === AuditMessageTypes.resolutionNote) {
          const first = {
            ...message,
            text: "",
          };
          const second = {
            _id: `${message._id}-custom-note`,
            title: `Resolution note has been sent to ${message?.to?.name}`,
            text: message?.text || "",
          };
          acc.push(first, second);
        } else {
          acc.push(message);
        }
        return acc;
      }, []);
    },
  },
};
</script>

<style lang="scss">
.review-messages {
  .v-stepper__wrapper {
    height: auto !important;
  }
}
</style>
