<template>
  <div>
    <v-card class="auditor-notes pa-6 pt-7">
      <div
        class="auditor-notes__header d-flex justify-space-between align-center"
      >
        <h4 class="auditor-notes__title font-weight-bold text-h6">
          Resolution note
        </h4>
      </div>

      <div class="auditor-notes__list d-flex flex-column mt-3 mb-3">
        <Note
          v-for="note in notes"
          :key="note._id"
          :note="note"
          :is-top-bar-active="note?.from?._id === user._id"
          class="mb-5 mt-2"
        >
          <template v-slot:text>
            <p class="pa-3 notes-text">
              {{ note.text }}
            </p>
          </template>

          <template
            v-if="!disabled && note?.from?._id === user._id"
            v-slot:top-bar
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attributes }">
                <v-icon v-bind="attributes" color="black" v-on="on">
                  mdi-dots-vertical
                </v-icon>
              </template>

              <v-list>
                <v-list-item
                  v-for="item in userActions"
                  :key="item.title"
                  @click="item.action(note)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </Note>
      </div>
      <v-btn
        v-if="!disabled"
        color="primary"
        rounded
        normal
        variant="elevated"
        @click="handleSendBtnClick"
      >
        Reply
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "pinia";
import Note from "./Note.vue";
import { useUserStore } from "../../stores/user";

export default {
  name: "ResolutionNotes",

  components: {
    Note,
  },

  props: {
    auditMessages: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const userActions = [
      {
        title: "Edit",
        action: this.handleReviewEdit,
      },
    ];

    return {
      userActions,
    };
  },

  computed: {
    ...mapState(useUserStore, ["user"]),
    notes() {
      return this.auditMessages.map((message) => ({
        ...message,
        userName: message?.from?.name,
      }));
    },
  },

  methods: {
    handleSendBtnClick() {
      this.$emit("reply");
    },
    handleReviewEdit(message) {
      this.$emit("edit", message);
    },
  },
};
</script>

<style lang="scss" scoped>
.auditor-notes {
  &__modal {
    z-index: 0;
  }

  .notes-text {
    background-color: #f0f0f0;
    border-radius: 5px;
  }
}
</style>
