<template>
  <div class="block-content">
    <transition
      mode="out-in"
      @before-enter="onBeforeEnter"
      @enter="onEnter"
      @after-enter="onAfterEnter"
      @before-leave="onBeforeLeave"
      @leave="onLeave"
    >
      <div
        v-if="!isExpanded"
        :style="{
          'max-height': `${lineHeight * visibleLines}px`,
        }"
        class="block-content__preview"
      >
        <p ref="preview">{{ content }}</p>
      </div>
      <p
        v-else
        :class="{
          'block-content__paragraph': true,
          'block-content__paragraph--is-expanded': isExpanded,
        }"
      >
        {{ content }}
      </p>
    </transition>
    <v-btn
      v-if="isToggleButtonRequired"
      color="primary"
      text
      class="pa-0"
      @click="isExpanded = !isExpanded"
    >
      {{ toggleCtaLabel }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ExpandableTextBlock",

  props: {
    content: {
      type: String,
      default: "",
    },
    visibleLines: {
      type: Number,
      default: 1,
    },
    lineHeight: {
      type: Number,
      default: 20,
    },
  },

  data() {
    return {
      isExpanded: false,
      isToggleButtonRequired: false,
    };
  },

  computed: {
    maxHeightCollapsed() {
      return this.lineHeight * this.visibleLines;
    },
    toggleCtaLabel() {
      return this.isExpanded ? "Read less" : "Read more";
    },
  },

  mounted() {
    const previewHeight = (this.$refs.preview ?? {}).clientHeight ?? 0;
    this.isToggleButtonRequired = previewHeight > this.maxHeightCollapsed;
  },

  methods: {
    onBeforeEnter(el) {
      el.style.height = this.maxHeightCollapsed + "px";
    },
    onEnter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    onAfterEnter(el) {
      el.style.overflow = "hidden";
    },
    onBeforeLeave(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.overflow = "hidden";
    },
    onLeave(el) {
      el.style.height = this.maxHeightCollapsed + "px";
      el.style.overflow = "hidden";
    },
  },
};
</script>

<style lang="scss" scoped>
.block-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
  gap: 8px;

  &__preview {
    white-space: pre-wrap;
    overflow: hidden;
    width: inherit;
  }

  &__paragraph {
    overflow: hidden;
    width: inherit;
    white-space: pre-wrap;
    transition: all 250ms ease-out;

    &.block-content__paragraph--is-expanded {
      overflow: initial;
    }
  }
}
</style>
