<template>
  <v-card class="case-details" :flat="!isReview">
    <v-card
      :flat="isReview"
      :class="!isReview ? 'pa-5' : 'pa-5 pb-0'"
      class="case-details mb-3"
    >
      <div class="d-flex">
        <div class="pr-6">
          <p class="text-subtitle-1 font-weight-bold">Case Reference</p>
          <p class="mb-5 text-subtitle-1 text--secondary">
            {{ get(caseData, "caseReference", "") }}
          </p>
        </div>
        <div>
          <p class="text-subtitle-1 font-weight-bold">Source</p>
          <p class="mb-5 text-subtitle-1 text--secondary">
            {{ get(caseData, "source", "") }}
          </p>
        </div>
      </div>

      <div
        class="case-details__clinicians d-flex justify-start flex-column flex-sm-row rounded mb-5"
      >
        <CaseDetailsClinician
          v-if="adviceCaseDate && adviceClinician"
          :clinician="adviceClinician"
          :disabled="isConsultationView"
          :class="{ 'disabled-case-info': isConsultationView }"
          label="Advice"
        />

        <v-divider :vertical="!$vuetify.breakpoint.sm" class="ma-0" />

        <CaseDetailsClinician
          v-if="consultationData && consultationClinician"
          :clinician="consultationClinician"
          :disabled="isAdviceView"
          :class="{ 'disabled-case-info': isAdviceView }"
          label="Consultation"
        />
      </div>

      <div class="d-flex flex-wrap mb-4">
        <CaseDetailsItem
          v-for="{ title, field } in headerFields"
          :key="title"
          :title="title"
          :description="get(caseData, field, '')"
          :width="$vuetify.breakpoint.mobile ? '50%' : '25%'"
          class="pr-2 pb-5 pb-md-2"
        >
          <template v-if="field === 'caseDate'" v-slot:description>
            {{ getDateForDisplay(caseData, "caseDate") }}
          </template>
        </CaseDetailsItem>
      </div>

      <template v-if="get(caseData, 'reportedCondition', null)">
        <v-divider />

        <CaseDetailsItem title="Reported Condition" class="pt-4 pb-4">
          <template v-slot:description>
            <ExpandableTextBlock
              :content="get(caseData, 'reportedCondition')"
            />
          </template>
        </CaseDetailsItem>
      </template>

      <v-row class="d-flex align-center">
        <v-col v-if="clinicalCodes.length">
          <h4
            class="mt-4 pa-0 font-weight-bold"
            color="black"
          >
            Clinical Codes
          </h4>
          <div class="mt-1 mb-3">
            <v-chip
              v-for="{ code, label } in clinicalCodes"
              :key="code"
              class="mr-2 mb-2"
            >
              {{ formatCode(code, label) }}
            </v-chip>
          </div>
        </v-col>
        <v-col v-if="caseData?.prescriptions">
          <h4 class="pa-0 font-weight-bold">Prescription</h4>
          <p class="pa-0 mb-3">
            {{ caseData.prescriptions }}
          </p>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      v-if="adviceCaseDate"
      :disabled="isConsultationView"
      :flat="isReview"
      :class="{
        'pa-5 mb-3': !isReview,
        'pa-5 pb-0 pt-0 mb-3': isReview,
        'disabled-case-info': isConsultationView,
      }"
    >
      <section v-if="adviceCaseDate" class="mb-6">
        <v-card-title class="pa-0 mb-3">
          {{ CaseSummaries.advice }}
        </v-card-title>

        <CaseDetailsSummary
          :clinician-name="adviceClinician.name"
          :start-time="getDateForDisplay(adviceCaseDate, 'beginTime')"
          :end-time="getDateForDisplay(adviceCaseDate, 'endTime')"
          :summary="adviceCaseDate.summary"
        />
      </section>
    </v-card>

    <v-card
      v-if="consultationData"
      :disabled="isAdviceView"
      :flat="isReview"
      :class="{
        'pa-5': !isReview,
        'pa-5 pb-0 pt-0': isReview,
        'disabled-case-info': isAdviceView,
      }"
    >
      <v-card-title class="pa-0 mb-3">
        {{ CaseSummaries.consultation }}
      </v-card-title>

      <CaseDetailsSummary
        :clinician-name="consultationClinician.name"
        :start-time="getDateForDisplay(consultationData, 'beginTime')"
        :end-time="getDateForDisplay(consultationData, 'endTime')"
        :summary="consultationData.summary"
        class="mb-3"
      />
    </v-card>
  </v-card>
</template>

<script>
import { get } from "@/helpers/get";
import { getDateForDisplay } from "@/helpers/getDateForDisplay";
import { getClinicalCodes } from "@/services/clinicalCodes";
import CaseDetailsItem from "./CaseDetailsItem.vue";
import CaseDetailsClinician from "./CaseDetailsClinician.vue";
import CaseDetailsSummary from "./CaseDetailsSummary.vue";
import ExpandableTextBlock from "../../common/ExpandableTextBlock.vue";
import { CaseAuditTypes } from "@/misc/constants";

export default {
  name: "CaseDetails",

  components: {
    CaseDetailsItem,
    CaseDetailsClinician,
    CaseDetailsSummary,
    ExpandableTextBlock,
  },

  props: {
    caseData: {
      type: Object,
      required: true,
    },
    caseViewType: {
      type: String,
      default: () => null,
    },
    isReview: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const headerFields = [
      { title: "Case Type", field: "caseType" },
      { title: "Case Date", field: "caseDate" },
      { title: "Priority", field: "latestPriority" },
      { title: "Patient Age", field: "age" },
      { title: "Patient Gender", field: "sex" },
      { title: "Location Name", field: "locationName" },
      { title: "Service", field: "clinicalService.name" },
      { title: "Case Tag", field: "caseTag" },
    ];
    const CaseSummaries = {
      advice: "Advice Summary",
      consultation: "Consultation Summary",
    };

    return {
      headerFields,
      CaseSummaries,
      clinicalCodes: [],
    };
  },

  computed: {
    isConsultationView() {
      return (
        this.caseViewType && this.caseViewType === CaseAuditTypes.consultation
      );
    },
    isAdviceView() {
      return this.caseViewType && this.caseViewType === CaseAuditTypes.advice;
    },
    consultationData() {
      if (!this.caseData?.consultation) return null;
      const { conclusionId = {} } = this.caseData.consultation;
      return conclusionId;
    },
    adviceCaseDate() {
      if (!this.caseData?.advice) return null;
      const { conclusionId = {} } = this.caseData.advice;
      return conclusionId;
    },
    consultationClinician() {
      if (!this.caseData?.consultation) return null;
      return this.caseData.consultation?.clinicianId;
    },
    adviceClinician() {
      if (!this.caseData?.advice) return null;
      return this.caseData.advice?.clinicianId;
    },
  },

  mounted() {
    this.fetchClinicalCodes();
  },

  methods: {
    get,
    getDateForDisplay,
    async fetchClinicalCodes() {
      const clinicalCodes = this.caseData.clinicalCodes?.filter(Boolean) || [];
      if (!clinicalCodes.length) return;

      const filter = { _id: { $in: clinicalCodes } };
      try {
        const response = await getClinicalCodes({ filter });
        this.clinicalCodes = response.data;
      } catch (error) {
        this.$notify({ type: "error", text: "Failed to fetch clinical codes" });
      }
    },
    formatCode(code, label) {
      return label ? `${code} - ${label}` : code;
    },
  },
};
</script>

<style lang="scss" scoped>
.case-details {
  &__clinicians {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .case-clinician-info {
    flex: 49.5%;
  }

  .disabled-case-info {
    background-color: #f9f9f9;
    pointer-events: none;
    user-select: none;
    color: rgba(0, 0, 0, 0.8) !important;
  }
}
</style>
